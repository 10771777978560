const CivilStateEnum = Object.freeze({
  '001': 'Casado',
  '002': 'Solteiro',
  '003': 'Divorciado',
  '1': 'Casado',
  '2': 'Solteiro',
  '3': 'Divorciado',
})

export default CivilStateEnum;
