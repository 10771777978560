<template>
    <Dialog :header="'Efetivação de ' + formLocal.name" :modal="true" :closable="false" :style="{ width: '45vw' }">
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading">
            <div class="field">
                <label for="resignationDate">Data de encerramento do antigo contrato
                </label>
                <Calendar class="w-full" :showClear="true" id="resignationDate" dateFormat="dd/mm/yy"
                    v-model="formEfetivacao.resignationDate" :show-icon="true"
                    :class="{ 'p-invalid': submitted && formLocal.admissionDate > formEfetivacao.resignationDate }" />
                <small class="p-error" v-if="submitted && formLocal.admissionDate > formEfetivacao.resignationDate">Data
                    de
                    demissão não pode ser menor que a data de admissão.</small>
                <small class="p-error" v-if="submitted && !formEfetivacao.resignationDate">Data de encerramento é
                    obrigatório.</small>
            </div>
            <Divider />
            <div class="field">
                <label for="admissionDate">Data de efetivação do funcionário</label>
                <Calendar class="w-full" :showClear="true" id="admissionDate" dateFormat="dd/mm/yy"
                    v-model="formEfetivacao.admissionDate" :show-icon="true"
                    :class="{ 'p-invalid': submitted && !formEfetivacao.admissionDate }" />
                <small class="p-error" v-if="submitted && !formEfetivacao.admissionDate">Data Admissão é
                    obrigatório.</small>
            </div>
            <div class="field">
                <label for="categoriaTrabalhador">Categoria eSocial</label>
                <DropdownCategoria class="w-full" v-model="formEfetivacao.categoriaTrabalhador"
                    :class="{ 'p-invalid': submitted && !formEfetivacao.categoriaTrabalhador }" />
                <small class="p-error" v-if="submitted && !formEfetivacao.categoriaTrabalhador">Categoria eSocial é
                    obrigatório.</small>
            </div>
            <div class="field">
                <label for="customerBranchesId">Unidade</label>
                <DropdownUnidade v-on:before-hide="onChangeFindHierarchy($event)" :loading="loadingHierarquia"
                    class="w-full" :customerId="form.customerId"
                    v-model="formEfetivacao.customerBranch"
                    :class="{ 'p-invalid': submitted && !formEfetivacao.customerBranch }" />
                <small class="p-error" v-if="submitted && !formEfetivacao.customerBranch">Campo
                    obrigatório.</small>
            </div>
            <div v-if="temPermissao()" class="formgrid grid">
                <div class="field col">
                    <label for="customer_department">Setor</label>
                    <DropdownSetor v-on:before-hide="onChangeFindHierarchy($event)" :loading="loadingHierarquia"
                        class="w-full" v-model="formEfetivacao.customerDepartment"
                        :customerId="form.customerId"
                        :class="{ 'p-invalid': submitted && !formEfetivacao.customerDepartmentId }" />
                    <small class="p-error"
                        v-if="submitted && !formEfetivacao.customerDepartmentId">Campo
                        obrigatório.</small>
                </div>
                <div class="field col">
                    <label for="customer_department">Cargo</label>
                    <DropdownCargo v-on:before-hide="onChangeFindHierarchy($event)" :loading="loadingHierarquia"
                        class="w-full" :customerId="form.customerId"
                        v-model="formEfetivacao.customerPosition"
                        :class="{ 'p-invalid': submitted && !formEfetivacao.customerPositionId }" />
                    <small class="p-error"
                        v-if="submitted && !formEfetivacao.customerPositionId">Campo
                        obrigatório.</small>
                </div>
            </div>
            <div v-if="!temPermissao()" class="formgrid grid">
                <div class="field col">
                    <label for="customer_department">Setor</label>
                    <DropdownSetorByHierarquia id="customerDepartments" v-on:before-hide="onChangeFindHierarchy($event)"
                        :loading="loadingHierarquia" class="w-full"
                        v-model="formEfetivacao.customerDepartment"
                        :customerId="form.customerId"
                        :branchId="formEfetivacao.customerBranchId"
                        :class="{ 'p-invalid': submitted && !formEfetivacao.customerDepartmentId }" />
                    <small class="p-error"
                        v-if="submitted && !formEfetivacao.customerDepartmentId">Campo
                        obrigatório.</small>
                </div>
                <div class="field col">
                    <label for="customer_department">Cargo</label>
                    <DropdownCargoByHierarquia id="customerPositionId" v-on:before-hide="onChangeFindHierarchy($event)"
                        :loading="loadingHierarquia" class="w-full"
                        v-model="formEfetivacao.customerPosition"
                        :customerId="form.customerId"
                        :branchId="formEfetivacao.customerBranchId"
                        :departmentId="formEfetivacao.customerDepartmentId"
                        :class="{ 'p-invalid': submitted && !formEfetivacao.customerPositionId }" />
                    <small class="p-error"
                        v-if="submitted && !formEfetivacao.customerPositionId">Campo
                        obrigatório.</small>
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="fecharEfetivar" />
            <Button label="Salvar" :disabled="possuiHierarquia || formEfetivacao.resignationDate === null" @click="save"
                icon="pi pi-check" autofocus />
        </template>
        <Toast @click="$router.push('/hierarchy/new')" style="cursor: pointer" group="funcao">
            <template #message="slotProps">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                <div class="flex ml-4 p-0">
                    <div class="text-center">
                        <h6>
                            {{ slotProps.message.summary }}<br>
                            <b>{{ slotProps.message.detail }}</b>
                        </h6>
                    </div>
                </div>
            </template>
        </Toast>

        <Toast @click="$router.push('/gestaoMedica/request')" style="cursor: pointer" group="solicitacao">
            <template #message="slotProps">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                <div class="flex ml-4 p-0">
                    <div class="text-left">
                        <h6> {{ slotProps.message.summary }}
                            <b>{{ slotProps.message.detail }}</b>
                        </h6>
                    </div>
                </div>
            </template>
        </Toast>
    </Dialog>
</template>

<script>
import BaseService from '@/services/BaseService';
import { getClientBase } from '@/services/http';
import DropdownCategoria from '../components/DropdownCategoria.vue';
import DropdownUnidade from '../../unidades/components/DropdownUnidade.vue';
import DropdownSetor from '../components/DropDownsPorCliente/DropdownSetorByCliente.vue';
import DropdownCargo from '../components/DropDownsPorCliente/DropdownCargoByCliente.vue';
import DropdownSetorByHierarquia from '../components/DropDownsPorHierarquia/DropdownSetorByHierarquia.vue';
import DropdownCargoByHierarquia from '../components/DropDownsPorHierarquia/DropdownCargoByHierarquia.vue';

export default {
    components: {
        DropdownUnidade,
        DropdownSetor,
        DropdownCargo,
        DropdownCategoria,
        DropdownCargoByHierarquia,
        DropdownSetorByHierarquia
    },
    props: ['form', 'fecharEfetivar', 'load'],
    model: {
        prop: 'form',
        event: 'formchange'
    },
    data() {
        return {
            loadingHierarquia: false,
            submitted: false,
            temHierarquia: false,
            hasHierarchy: true,
            novaCategoriaTrabalhador: null,
            departments: [],
            branches: [],
            positions: [],
            possuiHierarquia: false,
            oldId: null,
            loading: false,
            formEfetivacao: {
                customerHierarchyId: null,
                customerHierarchy: {},
                categoriaTrabalhador: null,
                resignationDate: null,
                admissionDate: null,
            },
        }
    },
    watch: {
        'formEfetivacao.customerBranch'() {
            this.formEfetivacao.customerBranchId = this.formEfetivacao?.customerBranch?.id;
            this.formEfetivacao.customerPosition = null;
            this.formEfetivacao.customerDepartment = null;
        },
        'formEfetivacao.customerDepartment'() {
            this.formEfetivacao.customerDepartmentId = this.formEfetivacao?.customerDepartment?.id;
            this.formEfetivacao.customerPosition = null;
            
        },
        'formEfetivacao.customerPosition'() {
            this.formEfetivacao.customerPositionId = this.formEfetivacao?.customerPosition?.id;
        },
    },
    computed: {
        formLocal: {
            get: function () {
                return this.form || {}
            },
            set: function (value) {
                this.$emit('formchange', value)
            }
        }
    },
    async created() {
        this.loading = true;
        this.$serviceHierarchy = new BaseService('/customer/hierarchies/has-hierarchy');
        this.$service = new BaseService('/customer/employees/efetivar-funcionario');
        this.$serviceEfetivarFuncionario = new BaseService('/customer/employees/efetivar-funcionario');
        this.formLocal.admissionDate = new Date(this.form.admissionDate);
        this.formEfetivacao.id = this.formLocal.id;
        this.formEfetivacao.customerId = this.formLocal.customerId;
        this.formEfetivacao.customerHierarchy.id = null;
        this.formEfetivacao.customerHierarchy.customerBranchId = null;
        this.formEfetivacao.customerHierarchy.customerDepartmentId = null;
        this.formEfetivacao.customerHierarchy.customerPositionId = null;
        this.formEfetivacao.customerHierarchy.customerId = this.formLocal.customerId;
        setTimeout(() => {
            this.pararLoading();
        }, 1000);
    },
    methods: {
        temPermissao() {
            return this.$checkPermission('estrutura_funcionario:exibir_sem_filtro_de_hierarquia')
        },
        pararLoading() {
            this.loading = false
        },
        async save() {
            this.submitted = true;
            try {
                await getClientBase().post(`/customer/employees/efetivar-funcionario/${this.formEfetivacao.id}`, this.formEfetivacao);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Funcionario efetivado com sucesso!',
                    life: 3000
                });
                this.submitted = false;
                this.load();
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao efetivar Funcionario! ' + message, life: 3000 });
            }
        },
        async onChangeFindHierarchy() {
            try {
                this.loadingHierarquia = true;

                if (this.formEfetivacao.customerId &&
                    this.formEfetivacao.customerBranchId &&
                    this.formEfetivacao.customerDepartmentId &&
                    this.formEfetivacao.customerPositionId) {
                    this.$toast.removeAllGroups();
                    const { data } = await this.$serviceHierarchy.findAll({
                        customerId: this.formEfetivacao.customerId,
                        customerBranchId: this.formEfetivacao.customerBranchId,
                        customerDepartmentId: this.formEfetivacao.customerDepartmentId,
                        customerPositionId: this.formEfetivacao.customerPositionId
                    })

                    if (!data) {
                        this.$toast.add({ severity: 'warn', summary: 'Hierarquia não encontrada!', life: 3000 });
                    }
                }
                this.loadingHierarquia = false;
            } catch (error) {
                this.loadingHierarquia = false;
            }
        },
    }
}
</script>
