<template>
    <AppPanel :location="['Clientes', 'Funcionario']" subtitle="Listagem de funcionarios">
        <template #content>
            <AppPaginatedGrid
                ref="grid"
                :service="service"
                permission="estrutura_funcionario"
                :extraActionItems="actionItems"
                @onToggleMenu="onToggleMenu"
                newPath="/employee/new"
                editPath="/employee/"
                :expander="true"
                subtitle="Funcionário"
                tooltip="Cadastro de funcionário"
                nomeTelaDoManual="employee-list"
                origemHistoricoAcao="/customer/employees"
            >
                <template #header>
                    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
                </template>
                <template #columns>
                    <Column field="id" :sortable="true" sortField="customer_employees.id" header="Código"></Column>
                    <Column field="situation" :sortable="true" sortField="customer_employees.situation" header="Situação"></Column>
                    <Column field="customer_employees.name" :sortable="true" sortField="customer_employees.name" header="Nome">
                        <template #body="slotProps">
                            {{ slotProps.data.name }}
                            <Tag
                                v-if="slotProps.data.multipleLinks"
                                style="background-color: var(--blue-100); color: var(--blue-900)"
                                class="ml-2"
                                value="Multiplo Vinculo"
                            ></Tag>
                            <Tag
                                v-if="slotProps.data.mudouRisco"
                                v-tooltip="'Colaborador deve realizar exame de mudança de função'"
                                style="background-color: var(--yellow-100); color: var(--blue-900)"
                                class="ml-2"
                                value="Pendencia"
                                @click="showDialogPendencia(slotProps.data)"
                            ></Tag>
                            <img v-if="slotProps.data.pcd" class="" src="/layout/images/deficiente.png" style="width: 20px" />
                            <small class="block text-500">{{ slotProps.data.email }}</small>
                        </template>
                    </Column>
                    <Column field="customer.name" :sortable="true" sortField="customer.name" header="Cliente"></Column>
                    <Column field="branch.name" :sortable="true" sortField="branch.name" header="Unidade"></Column>
                    <Column field="department.name" :sortable="true" sortField="department.name" header="Setor"> </Column>
                    <Column field="position.name" :sortable="true" sortField="position.name" header="Cargo"></Column>
                    <Column header="Codigo SOC" :sortable="true">
                        <template #body="{ data }">
                            <div v-if="!data.customer?.integracaoSoc" class="field mt-1 mb-0" style="text-align: center">
                                {{ 'Sem integração' }}
                            </div>
                            <div v-if="data.customer?.integracaoSoc && !data.codigoSoc" class="field mt-1 mb-0" style="text-align: center">
                                <i
                                    v-tooltip="'Cadastro sem codigo do SOC'"
                                    class="pi pi-exclamation-circle"
                                    style="font-size: 2rem; color: #ff9900; cursor: pointer"
                                    @click="logs(data)"
                                />
                            </div>
                            <div v-if="data.customer?.integracaoSoc && data.codigoSoc" class="field mt-1 mb-0" style="text-align: center">
                                {{ data.codigoSoc }}
                            </div>
                        </template>
                    </Column>
                </template>

                <template #expansion="slotProps">
                    <Detalhes :slotProps="slotProps"></Detalhes>
                </template>
            </AppPaginatedGrid>
        </template>
    </AppPanel>

    <NovaFuncaoDialog
        v-if="showNovaFuncaoDialog"
        v-model:visible="showNovaFuncaoDialog"
        v-bind:form="form"
        v-bind:fecharNewFunction="fecharNewFunction"
        v-bind:load="load"
    ></NovaFuncaoDialog>

    <EfetivoDialog
        v-if="showEfetivoDialog"
        v-model:visible="showEfetivoDialog"
        v-bind:form="form"
        v-bind:fecharEfetivar="fecharEfetivar"
        v-bind:load="load"
    >
    </EfetivoDialog>

    <AppAnexoDialog
        v-if="showAnexoDialog"
        v-model:visible="showAnexoDialog"
        v-model:origemId="record.id"
        :enableImport="false"
        :enableRemove="false"
        formatoDaData="date"
        title="ASOs para o Funcionário"
        tipo="ASO"
        origem="FUNCIONARIO_ASO"
        @onClickCloseAnexo="showAnexoDialog = false"
    >
    </AppAnexoDialog>

    <AppLogErroDialog
        v-if="showLogsDialog"
        v-model:visible="showLogsDialog"
        title="Logs para o funcionário"
        origem="CADASTRO_FUNCIONARIO"
        v-model:origemId="record.id"
        @onClose="showLogsDialog = false"
    >
    </AppLogErroDialog>
    <DialogResolvePendencia ref="dialogResolverPendencia" v-bind:load="load" />
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCustomers, getCurrentCompany } from '@/services/store';
import NovaFuncaoDialog from './components/NovaFuncaoDialog';
import EfetivoDialog from './components/EfetivoDialog.vue';
import Detalhes from './components/Detalhes';
import CategoriaTrabalhadorEnum from '../../enums/CategoriaTrabalhadorEnum';
import SituationEnum from '../../enums/SituationEnum';
import { getClientBase } from '../../services/http';
import DialogResolvePendencia from './components/DialogResolvePendencia.vue';

export default {
    components: {
        NovaFuncaoDialog,
        Detalhes,
        EfetivoDialog,
        DialogResolvePendencia
    },

    data() {
        return {
            funcionarioCompleto: null,
            showNovaFuncaoDialog: false,
            showEfetivoDialog: false,
            showAnexoDialog: false,
            showLogsDialog: false,
            form: {},
            record: {},
            recordDialog: false,
            service: null,
            loading: false,
            actionRecord: {},
            tenantCustomers: [],
            actionItems: [
                {
                    label: 'Nova Função',
                    icon: 'pi pi-pencil',
                    disabled: () =>
                        !this.isEfetivo(this.record) ||
                        this.isPendente(this.record) ||
                        this.isDemitido(this.record) ||
                        this.isInativo(this.record) ||
                        !this.$checkPermission('estrutura_funcionario:nova_funcao'),
                    command: () => this.newFunction(this.record)
                },
                {
                    label: 'Efetivação',
                    icon: 'pi pi-user-plus',
                    disabled: () =>
                        this.isEfetivo(this.record) || this.isDemitido(this.record) || !this.$checkPermission('estrutura_funcionario:efetivacao'),
                    command: () => this.efetivar(this.record)
                },
                {
                    label: 'Histórico de ASO',
                    icon: 'pi pi-paperclip',
                    disabled: () => !this.$checkPermission('estrutura_funcionario:historico_de_aso'),
                    command: () => this.historicoAso(this.record)
                },
                {
                    label: 'Logs',
                    icon: 'pi pi-chart-bar',
                    command: () => this.logs(this.record)
                }
            ]
        };
    },
    async mounted() {
        this.tenantCustomers = getCurrentCustomers();
        this.load();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/customer/employees');
    },
    methods: {
        showDialogPendencia(record) {
            this.$refs.dialogResolverPendencia.open(record);
        },
        isEfetivo(record) {
            return !(
                record.categoriaTrabalhador.codigo === CategoriaTrabalhadorEnum.APRENDIZ ||
                record.categoriaTrabalhador.codigo === CategoriaTrabalhadorEnum.ESTAGIARIO
            );
        },
        isDemitido(record) {
            return record?.situation === SituationEnum.DEMITIDO;
        },
        isPendente(record) {
            return record?.mudouRisco;
        },
        isInativo(record) {
            return record?.situation === SituationEnum.INATIVO;
        },
        newFunction(data) {
            this.form = data;
            this.form.changeDate = null;
            this.form.customerHierarchy.customerId = null;
            this.form.customerHierarchy.customerBranchId = null;
            this.form.customerHierarchy.customerDepartmentId = null;
            this.form.customerHierarchy.customerPositionId = null;
            this.form.customerHierarchy.id = null;
            this.showNovaFuncaoDialog = !this.showNovaFuncaoDialog;
        },
        efetivar(data) {
            this.form = data;
            this.showEfetivoDialog = !this.showEfetivoDialog;
        },
        fecharNewFunction() {
            this.showNovaFuncaoDialog = !this.showNovaFuncaoDialog;
        },
        fecharEfetivar() {
            this.showEfetivoDialog = !this.showEfetivoDialog;
        },
        onToggleMenu(event, data) {
            this.actionRecord = data;
            this.record = data;
        },
        async load() {
            this.showNovaFuncaoDialog = false;
            this.showEfetivoDialog = false;
            this.$refs.grid.load();
        },
        async historicoAso(data) {
            try {
                this.loading = true;
                this.record = data;
                await getClientBase().post('/customer/employees/sincronizar-asos-soc/' + this.record.id);
                this.showAnexoDialog = true;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'info',
                    summary: message,
                    life: 3000
                });
            }
        },
        async logs(data) {
            this.record = data;
            this.showLogsDialog = true;
        }
    }
};
</script>
