<template>
    <AppOnePageDropdown
        v-model="value"
        :idValue="idValue"
        optionLabel="name"
        placeholder="Selecione o setor..."
        :service="$service"
        :itemSize="itemSize"
        :filterFields="['codigoExterno', 'name']"
        :renderValue="customRenderValue"
        :renderOption="customRenderOption"
        :filtrosExtras="{ ativo: true, customerId: $props.customerId }"
    >
    </AppOnePageDropdown>
</template>
<script>
import BaseService from '../../../services/BaseService';

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        },
        idValue: {
            type: Number
        },
        customerId: {
            type: Number
        }
    },
    data() {
        return {
            $service: null
        };
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new BaseService('/customer/departments');
    },
    methods: {
        customRenderOption(slotProps) {
            const nome = (slotProps.option?.codigoExterno ? slotProps.option?.codigoExterno + ' - ' : '') + slotProps.option?.name;

            return nome;
        },
        customRenderValue(slotProps) {
            const nome = (slotProps.value?.codigoExterno ? slotProps.value?.codigoExterno + ' - ' : '') + slotProps.value?.name;

            return nome;
        }
    }
};
</script>
