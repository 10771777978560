<template>
    <div class="p-panel-header" :style="{ backgroundColor:'var(--gray-300)' }">
        <div class="p-6 m-4 " :style="{ width: '100%' }" >
            <h5 class="mb-3">Dados Pessoais</h5>
            <div class="grid">
                <div class="col-2">
                    <strong class="block my-2">Sexo</strong>
                    {{ sex[slotProps.data.sex] ? sex[slotProps.data.sex] : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">Estado civil</strong>
                    {{ civilState[slotProps.data.civilState] ? civilState[slotProps.data.civilState] : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">Deficiência</strong>
                    {{ booleanEnum[slotProps.data.deficiency] ? booleanEnum[slotProps.data.deficiency] : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">E-mail</strong>
                    {{ slotProps.data.email ? slotProps.data.email : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">Telefone Celular</strong>
                    {{ slotProps.data.phone ? slotProps.data.phone : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">Data de Nascimento</strong>
                    {{ slotProps.data.dataNascimento ? $filters.formatDateOnly(slotProps.data.dataNascimento) : 'Não informado' }}
                </div>
            </div>

            <Divider class="my-4" />

            <h5 class="mt-3 mb-3">Contratação</h5>

            <div class="grid">
                <div class="col-2">
                    <strong class="block my-2">Data Admissão</strong>
                    {{ slotProps.data.admissionDate ? $filters.formatDateOnly(slotProps.data.admissionDate) 
                    : 'Não informado'}}
                </div>
                <template v-if="slotProps.data.resignationDate">
                    <div class="col-2">
                        <strong class="block my-2">Data Demissão</strong>
                        {{ slotProps.data.resignationDate ? $filters.formatDateOnly(slotProps.data.resignationDate) 
                        :'Não informado' }}
                    </div>
                </template>
                <div class="col-2">
                    <strong class="block my-2">Contratação</strong>
                    {{ slotProps.data.contractType ? slotProps.data.contractType : 'Não informado' }}

                </div>
                <div class="col-2">
                    <strong class="block my-2">Efetivo?</strong>
                    {{ booleanEnum[slotProps.data.effective] ? booleanEnum[slotProps.data.effective] : 'Não informado' }}

                </div>
                <div class="col-2">
                    <strong class="block my-2">Matrícula Cliente</strong>
                    {{ slotProps.data.matriculaCliente ?? 'Não informado' }}
                </div>
            </div>

            <Divider class="my-4" />

            <h5 class="mt-3 mb-3">Documentos pessoais</h5>
            <div class="grid">
                <div class="col-2">
                    <strong class="block my-2">CPF</strong>
                    {{ slotProps.data.cpf ? $filters.cpf(slotProps.data.cpf) : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">RG</strong>
                    {{ slotProps.data.rg ? slotProps.data.rg +' (' + slotProps.data.rgDispatcher + '/' + slotProps.data.rgState + ')' : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">Data emissão RG</strong>
                    {{ slotProps.data.rgDate ? $filters.formatDateOnly(slotProps.data.rgDate) : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">CTPS</strong>
                    {{ slotProps.data.ctps ? slotProps.data.ctps + '/' + slotProps.data.ctpsState : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">PIS/PASEP</strong>
                    {{ slotProps.data.pisPasep ? $filters.pis(slotProps.data.pisPasep) : 'Não informado' }}
                </div>
                <div class="col-2">
                    <strong class="block my-2">Endereço</strong>
                    {{ slotProps.data.address ? slotProps.data.address + ',' : ''  }}
                    {{ slotProps.data.number ? slotProps.data.number + '-' : ''  }}
                    {{ slotProps.data.addressComplement ? slotProps.data.addressComplement + '-' : ''  }}
                    {{ slotProps.data.neighborhood ? slotProps.data.neighborhood + ',' : ''  }}
                    {{ slotProps.data.city ? slotProps.data.city + ' / ' : ''  }}
                    {{ slotProps.data.state ? slotProps.data.state + ' - ' : ''  }}
                    {{ slotProps.data.zipCode ? $filters.zipcode(slotProps.data.zipCode) : ''  }}
                    <br>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SexEnum from '@/enums/SexEnum'
import CivilStateEnum from '@/enums/CivilStateEnum'
import BooleanEnum from '../../../enums/BooleanEnum';

export default {
    props: ['slotProps'],
    data() {
        return {
            sex: SexEnum,
            civilState: CivilStateEnum,
            booleanEnum: BooleanEnum
        }
    }
};
</script>
