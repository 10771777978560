<template>
    <Dialog header="Troca de Função realizada com sucesso!" :modal="true" :closable="false" :style="{ width: '55vw' }">
        <div style="text-align: center">
            <i class="pi pi-exclamation-triangle mb-3" style="font-size: 5rem; color: var(--yellow-500); cursor: pointer" />
        </div>
        <div class="flex justify-content-center flex-wrap mb-1" style="text-align: center">
            <h5 v-if="riscosExamesIguais" class="m-0">A mudança de função não gerou uma mudança de riscos!</h5>
            <h5 v-else class="m-0">A mudança de função gerou uma mudança de riscos!</h5>
        </div>
        <div class="grid m-1 p-1">
            <div class="card col-5 m-5">
                <p style="font-weight: bold; text-align: center">Riscos Antigos</p>
                <ul class="p-2">
                    <li style="list-style-type: none" v-for="riscoMantido in riscosMantidos" :key="riscoMantido">
                        <i class="pi pi-check"></i>
                        {{ riscoMantido }}
                    </li>
                    <li style="color: #ea0000; list-style-type: none" v-for="riscoAntigo in riscosAntigos" :key="riscoAntigo">
                        <i class="pi pi-minus"></i>
                        {{ riscoAntigo }}
                    </li>
                </ul>
            </div>
            <div class="card col-5 m-5">
                <p style="font-weight: bold; text-align: center">Riscos Novos</p>
                <ul class="p-2">
                    <li style="list-style-type: none" v-for="riscoMantido in riscosMantidos" :key="riscoMantido">
                        <i class="pi pi-check"></i>
                        {{ riscoMantido }}
                    </li>
                    <li style="color: #2b9e00; list-style-type: none" v-for="riscoNovo in riscosNovos" :key="riscoNovo">
                        <i class="pi pi-plus"></i>
                        {{ riscoNovo }}
                    </li>
                </ul>
            </div>
        </div>
        <h5 v-if="riscosExamesIguais" class="flex justify-content-center flex-wrap mt-2 mb-0" style="text-align: center">
            {{ mensagemRiscosExamesIguais }}
        </h5>
        <h5 v-else class="flex justify-content-center flex-wrap mt-2 mb-0" style="text-align: center">
            {{ mensagemSolicitarExame }}
        </h5>

        <template #footer>
            <Button label="Sair" icon="pi pi-times" class="p-button-text" @click="fecharMudancaRiscos" />
            <template v-if="!riscosExamesIguais">
                <Button
                    v-if="form?.riscos?.length || riscosNovos?.length"
                    label="Remover pendência e sair"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-info"
                    @click="removerPendencia"
                />
                <Button label="Solicitar Exame" icon="pi pi-check" autofocus @click="solicitarExame" />
            </template>
        </template>
    </Dialog>
</template>

<script>
import eventBus from '../../../event-bus';
import { CriarAgendamentoDialogDto } from '../../gestao-medica-v3/agendamentos/criar-agendamento/steps/dtos/CriarAgendamentoDialogDto';
import AtualizadorFuncionarioExames from '../../gestao-medica-v3/agendamentos/dialog-reserva-horario/utils/AtualizadorFuncionarioExames';
import { NovaFuncaoService } from './NovaFuncao/NovaFuncaoService';

export default {
    props: ['fecharMudancaRiscos', 'riscos', 'form'],
    data() {
        return {
            riscosAntigos: [],
            riscosMantidos: [],
            riscosNovos: [],
            mensagemSolicitarExame: `Por favor solicite o
            exame de mudança de riscos ocupacionais para o colaborador.`,
            mensagemDiminuicaoRiscos: `Essa alteração diminuiu os riscos atrelado ao funcionário,
            desta forma não é necessário realizar o exame mudança de riscos ocupacionais, para concluir
            clique em remover pendencia e sair.`,
            mensagemRiscosExamesIguais: `Não há necessidade de
            realizar um exame ocupacional do tipo Mudança de riscos
            ocupacionais pois os riscos e exames são os mesmos!`
        };
    },
    async mounted() {
        this.riscosAntigos = this.riscos.data.riscosAntigos;
        this.riscosMantidos = this.riscos.data.riscosMantidos;
        this.riscosNovos = this.riscos.data.riscosNovos;
        const atualizador = new AtualizadorFuncionarioExames({});
        await atualizador.buscarRiscosDoFuncionario(this.form);
    },
    methods: {
        async solicitarExame() {
            if (this.$checkPermission('gestao_medica_v3:view')) {
                await this.$router.push({ name: 'agendamentos-v3-index' });
                const dto = new CriarAgendamentoDialogDto({
                    funcionario: {
                        removePendenciaNovaFuncaoId: this.form.id
                    }
                });
                eventBus.emit('onOpenDialogFormAgendamento', dto);
            } else {
                await this.$router.push('/gestao-medica/agendamentos');
            }
        },
        async removerPendencia() {
            await new NovaFuncaoService().retirarPendenciaFuncionario(this.form.id);
            this.fecharMudancaRiscos();
        }
    },
    computed: {
        riscosExamesIguais() {
            return !this.riscosAntigos?.length && !this.riscosNovos?.length && this.riscosMantidos?.length;
        }
    }
};
</script>
