<template>
    <Dialog :visible="isOpen" ref="showModalAvisoPreSolicitacoes" :modal="true" :closable="false">
        <div style="text-align: center">
            <i class="pi pi-exclamation-triangle mb-3" style="font-size: 5rem; color: var(--yellow-500); cursor: pointer" />
        </div>
        <div class="flex justify-content-center flex-wrap mb-1" style="text-align: center">
            <h5 class="m-0">A mudança de função gerou mudança de risco!</h5>
        </div>
        <h5>Há mudanças de risco ao funcionário, verificar as pendências exigidas.</h5>
        <template #footer>
            <Button label="Sair" icon="pi pi-times" class="p-button-text" @click="close" />
            <Button
                v-if="funcionario?.riscos?.length !== 0"
                label="Remover pendência e sair"
                icon="pi pi-times"
                class="p-button-outlined p-button-info"
                @click="removerPendencia"
            />
            <Button label="Solicitar Exame" icon="pi pi-check" autofocus @click="solicitarExame" />
        </template>
    </Dialog>
</template>

<script>
import eventBus from '../../../event-bus';
import { CriarAgendamentoDialogDto } from '../../gestao-medica-v3/agendamentos/criar-agendamento/steps/dtos/CriarAgendamentoDialogDto';
import AtualizadorFuncionarioExames from '../../gestao-medica-v3/agendamentos/dialog-reserva-horario/utils/AtualizadorFuncionarioExames';
import { FuncionarioDto } from './dtos/FuncionarioDto';
import { NovaFuncaoService } from './NovaFuncao/NovaFuncaoService';

export default {
    emits: ['onOpenDialogFormAgendamento'],
    props: ['load'],
    data() {
        return {
            showModalAvisoPreSolicitacoes: false,
            isOpen: false,
            funcionario: new FuncionarioDto({ id: '' })
        };
    },
    methods: {
        fecharModal() {
            this.showModalAvisoPreSolicitacoes = false;
        },
        async removerPendencia() {
            await new NovaFuncaoService().retirarPendenciaFuncionario(this.funcionario.id);
            this.close();
            this.$toast.add({ severity: 'success', summary: 'Removido a pendência', life: 3000 });
            this.load();
        },
        async open(record) {
            const atualizador = new AtualizadorFuncionarioExames({});
            this.funcionario.id = record.id;
            await atualizador.buscarRiscosDoFuncionario(this.funcionario);
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        },
        async solicitarExame() {
            if (this.$checkPermission('gestao_medica_v3:view')) {
                await this.$router.push({ name: 'agendamentos-v3-index' });
                const dto = new CriarAgendamentoDialogDto({
                    funcionario: {
                        removePendenciaNovaFuncaoId: this.funcionario.id
                    }
                });
                eventBus.emit('onOpenDialogFormAgendamento', dto);
            } else {
                await this.$router.push('/gestao-medica/agendamentos');
            }
        }
    }
};
</script>
